import { useState } from "react";

import { Button, Space, Card, Row, Form, Input, InputNumber, Col, Upload, Select } from "antd";

import { MulticastGroup } from "@chirpstack/chirpstack-api-grpc-web/api/multicast_group_pb";
import MulticastGroupStore from "../../stores/MulticastGroupStore";

import { onFinishFailed } from "../helpers";

import {
  DeploymentDevice,
  MulticastGroupType,
  Deployment, 
  CreateDeploymentRequest, 
  RequestFragmentationSessionStatus
} from "@chirpstack/chirpstack-api-grpc-web/api/fuota_pb";

import { Duration } from 'google-protobuf/google/protobuf/duration_pb';

const TEST_STRING = "U3Vuc2hpbmUgYWx3YXlzIGJyaW5ncyBhIGNoZWVyZnVsIG1vb2QsIGJyaWdodGVuaW5nIGV2ZXJ5IGNvcm5lciB3aXRoIHdhcm10aCBhbmQgbGlnaHQsIHNwYXJraW5nIGpveWZ1bCBkYXlzLg==";

interface IProps {
  mg: MulticastGroup;
}

interface FormRules {
  multicastTimeout: number;
  unicastTimeout: number;
  unicastAttemptCount: number;
  fragmentationSize: number;
  fragmentationRedundancy: number;
  fragmentationBlockAckDelay: number;
  fragmentationDescriptor: string;
  requestFragmentationSessionStatus: RequestFragmentationSessionStatus;
  payload: Uint8Array | string, 
}

function MulticastDeployment(props: IProps) {
  const [form] = Form.useForm<FormRules>();

  const onFinish = (values: FormRules) => {
    const item = new Deployment();
    const req = new CreateDeploymentRequest();
    
    item.setApplicationId(props.mg.getApplicationId());

    const device = new DeploymentDevice();
    device.setDevEui('0080e115053a710d');
    device.setMcRootKey('00000000000000000000000000000000');
    item.setDevicesList([device])

    item.setMulticastGroupType(MulticastGroupType.CLASS_C);
    item.setMulticastDr(props.mg.getDr());
    item.setMulticastFrequency(props.mg.getFrequency());
    item.setMulticastGroupId(0);
    
    item.setMulticastTimeout(values.multicastTimeout);
    item.setUnicastTimeout(new Duration().setSeconds( values.unicastTimeout * 60 ));
    item.setUnicastAttemptCount(values.unicastAttemptCount);
    item.setFragmentationFragmentSize(values.fragmentationSize);

    item.setPayload(TEST_STRING);

    item.setFragmentationRedundancy(values.fragmentationRedundancy);
    item.setFragmentationSessionIndex(0);
    item.setFragmentationMatrix(0);
    item.setFragmentationBlockAckDelay(values.fragmentationBlockAckDelay);
    item.setFragmentationDescriptor(values.fragmentationDescriptor);
    item.setRequestFragmentationSessionStatus(values.requestFragmentationSessionStatus);

    console.log(item);
    console.log( item.serializeBinary() );

    req.setDeployment(item);
    console.log( req.serializeBinary() );
    MulticastGroupStore.fuota(req, () => {console.log("Successful Deployment")})
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <Card title="Update Firmware">
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          initialValues={{ 
            multicastTimeout: 6,
            unicastTimeout: 6,
            unicastAttemptCount: 1,
            fragmentationSize: 50,
            fragmentationRedundancy: 3,
            fragmentationBlockAckDelay: 1,
            fragmentationDescriptor: 'firm',
            requestFragmentationSessionStatus: RequestFragmentationSessionStatus.AFTER_SESSION_TIMEOUT,
          }}
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="multicastTimeout" label="Multicast Timeout" rules={[{ required: true, message: "Please input a Timeout!" }]}>
                <InputNumber min={1} max={60} style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="unicastTimeout" label="Unicast Timeout" rules={[{ required: true, message: "Please input a Timeout!" }]}>
                <InputNumber min={1} max={60} style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="unicastAttemptCount" label="Unicast Attempt Count" rules={[{ required: true, message: "Please input an Attempt Count!" }]}>
                <InputNumber min={1} max={5} style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="fragmentationSize" label="Fragmentation Size" rules={[{ required: true, message: "Please input a fragmentation size" }]}>
                <InputNumber min={1} max={254} style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="fragmentationRedundancy" label="Fragmentation Redundancy" rules={[{ required: true, message: "Please input a Fragmentation Redundancy" }]}>
                <InputNumber min={1} max={60} style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="fragmentationBlockAckDelay" label="Fragmentation Block Ack Delay" rules={[{ required: true, message: "Please input a Block Ack Delay!" }]}>
                <InputNumber min={1} max={60} style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="fragmentationDescriptor" label="Fragmentation Descriptor" rules={[{ required: true, message: "Please input a Fragmentation descriptor" }]}>
                <Input minLength={4} maxLength={4} style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Fragmentation Session Status Request"
                name="requestFragmentationSessionStatus"
                tooltip="When in the update procedure the status is to be requested from the individual devices."
                rules={[{ required: true, message: "Please select a status request type!" }]}
              >
              <Select>
              <Select.Option value={RequestFragmentationSessionStatus.AFTER_FRAGMENT_ENQUEUE}>After Fragments Enqueued</Select.Option>
              <Select.Option value={RequestFragmentationSessionStatus.AFTER_SESSION_TIMEOUT}>Afer Session Timeout</Select.Option>
              <Select.Option value={RequestFragmentationSessionStatus.NO_REQUEST}>Do not Request</Select.Option>
              </Select>
            </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="payload" label="Binary File" rules={[{ required: false, message: "Please select a file to upload" }]}>
                <Upload type="drag" style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" htmlType="submit">
            Deploy
          </Button>
        </Form>
      </Card>
    </Space>
  );
}

export default MulticastDeployment;
