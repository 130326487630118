import { useState } from "react";

import { Switch } from "antd";
import { Button, Tabs, Space, Card, Row, Form, Input, InputNumber, Popconfirm } from "antd";
import type { ColumnsType } from "antd/es/table";
import { RedoOutlined, DeleteOutlined } from "@ant-design/icons";
import { Buffer } from "buffer";

import type { MulticastGroup, ListMulticastGroupQueueResponse } from "@chirpstack/chirpstack-api-grpc-web/api/multicast_group_pb";
import {
  EnqueueMulticastGroupQueueItemRequest,
  ListMulticastGroupQueueRequest,
  FlushMulticastGroupQueueRequest,
  MulticastGroupQueueItem
} from "@chirpstack/chirpstack-api-grpc-web/api/multicast_group_pb";

import { onFinishFailed } from "../helpers";
import type { GetPageCallbackFunc } from "../../components/DataTable";
import DataTable from "../../components/DataTable";
import MulticastGroupStore from "../../stores/MulticastGroupStore";
import CodeEditor from "../../components/CodeEditor";

interface IProps {
  mg: MulticastGroup;
}

interface FormRules {
  confirmed: boolean;
  fPort: number;
  isEncrypted: boolean;
  fCntDown: number;
  hex: string;
  base64: string;
  json: string;
}

function MulticastQueue(props: IProps) {
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [isEncrypted, setIsEncrypted] = useState<boolean>(false);
  const [form] = Form.useForm<FormRules>();
  const [isSet, setIsSet] = useState<boolean>(false);

  const columns: ColumnsType<MulticastGroupQueueItem.AsObject> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 350,
    },
    {
      title: "Pending",
      dataIndex: "isPending",
      key: "isPending",
      width: 100,
      render: (text, record) => {
        return "no";
      },
    },
    {
      title: "Encrypted",
      dataIndex: "isEncrypted",
      key: "isEncrypted",
      width: 100,
      render: (text, record) => {
        return "no";
      },
    },
    {
      title: "Frame-counter",
      dataIndex: "fCntDown",
      key: "fCntDown",
      width: 200,
      render: (text, record) => {
        return record.fCnt
      },
    },
    {
      title: "Confirmed",
      dataIndex: "confirmed",
      key: "confirmed",
      width: 100,
      render: (text, record) => {
        return "no";
      },
    },
    {
      title: "FPort",
      dataIndex: "fPort",
      key: "fPort",
      width: 100,
    },
    {
      title: "Data (HEX)",
      dataIndex: "data",
      key: "data",
      render: (text, record) => {
        return Buffer.from(record.data as string, "base64").toString("hex");
      },
    },
  ];

  const getPage = (limit: number, offset: number, callbackFunc: GetPageCallbackFunc) => {
    const req = new ListMulticastGroupQueueRequest();
    req.setMulticastGroupId(props.mg.getId());
    MulticastGroupStore.listQueue(req, (resp: ListMulticastGroupQueueResponse) => {
      const obj = resp.toObject();
      callbackFunc(obj.itemsList.length, obj.itemsList);
    });
  };

  const refreshQueue = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const flushQueue = () => {
    const req = new FlushMulticastGroupQueueRequest();
    req.setMulticastGroupId(props.mg.getId());
    MulticastGroupStore.flushQueue(req, () => {
      refreshQueue();
    });
  };

  const onEnqueue = (values: FormRules) => {
    const req = new EnqueueMulticastGroupQueueItemRequest();
    const item = new MulticastGroupQueueItem();

    item.setMulticastGroupId(props.mg.getId());
    item.setFPort(values.fPort);
    item.setFCnt(values.fCntDown);

    if (values.hex !== undefined) {
      item.setData(new Uint8Array(Buffer.from(values.hex, "hex")));
    }

    else if (values.base64 !== undefined) {
      item.setData(new Uint8Array(Buffer.from(values.base64, "base64")));
    }

    else{
      if(isSet)
        item.setData(new Uint8Array(Buffer.from("set", "ascii")));
      else
        item.setData(new Uint8Array(Buffer.from("clr", "ascii")));
    }

    req.setQueueItem(item);

    MulticastGroupStore.enqueue(req, _ => {
      form.resetFields();
      form.setFieldValue("Set or Clear", isSet);
      setIsEncrypted(false);
      refreshQueue();
    });
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <Card title="Enqueue">
        <Form
          layout="horizontal"
          onFinish={onEnqueue}
          onFinishFailed={onFinishFailed}
          form={form}
          initialValues={{ fPort: 1 }}
        >
          <Row>
            <Space direction="horizontal" style={{ width: "100%" }} size="large">
              <Form.Item name="confirmed" label="Confirmed" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item name="fPort" label="FPort">
                <InputNumber min={1} max={254} />
              </Form.Item>
              <Form.Item
                name="isEncrypted"
                label="Is encrypted"
                valuePropName="checked"
                tooltip="Only enable this in case the payload that you would like to enqueue has already been encrypted. In this case you also must enter the downlink frame-counter which has been used for the encryption."
              >
                <Switch onChange={setIsEncrypted} />
              </Form.Item>
              {isEncrypted && (
                <Form.Item
                  name="fCntDown"
                  label="Downlink frame-counter used for encryption"
                  rules={[{ required: true, message: "Please enter a downlink frame-counter!" }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              )}
            </Space>
          </Row>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="HEX" key="1">
              <Form.Item name="hex">
                <Input />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane tab="BASE64" key="2">
              <Form.Item name="base64">
                <Input />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane tab="JSON" key="3">
              <CodeEditor name="json" />
            </Tabs.TabPane>
            <Tabs.TabPane tab="SWITCH" key="4">
              <Form.Item name="Set or Clear">
                <Switch onChange={setIsSet} value={isSet}/>
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
          <Button type="primary" htmlType="submit">
            Enqueue
          </Button>
        </Form>
      </Card>
      <Row justify="end">
        <Space direction="horizontal" size="large">
          <Button icon={<RedoOutlined />} onClick={refreshQueue}>
            Reload
          </Button>
          <Popconfirm title="Are you sure you want to flush the queue?" placement="left" onConfirm={flushQueue}>
            <Button icon={<DeleteOutlined />}>Flush queue</Button>
          </Popconfirm>
        </Space>
      </Row>
      <DataTable columns={columns} getPage={getPage} refreshKey={refreshCounter} rowKey="id" noPagination />
    </Space>
  );
}

export default MulticastQueue;
